<template>
  <router-link class="look"
               :to="lookRoute">
    <img :src="look.previewUrl">
    <div class="see-more">See More</div>
  </router-link>
</template>

<script>
export default {
  props: {
    look: {
      required: true,
      type: Object
    }
  },

  computed: {
    lookRoute () {
      return {
        name: 'look',
        params: {
          userId: this.look.authorId,
          lookId: this.look.id
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .look {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 250px;
    padding: 20px;

    background: #fff;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.06);

    border-radius: 10px;
    border: 1px solid transparent;
    transition: border 0.2s ease-out;

    &:hover {
      border: 1px solid $primary-color;
    }

    & > img {
      width: 100%;
      max-width: 200px;
      max-height: 200px;
    }
  }

  .see-more {
    font-size: 16px;
  }
</style>
