<template>
  <div class="user-looks">
    <look-card class="look"
               v-for="look in looks"
               :key="look.id"
               :look="look"/>
  </div>
</template>

<script>
  import lookService from '@/services/queries/lookQueries'
  import LookCard from '@/components/Shared/LookCard/LookCard.vue'
  import bus from '@/bus.js'
  import hasBackgroundMixin from '@/services/mixins/hasBackgroundMixin.js'

  export default {
    props: {
      type: {
        required: true,
        default: 'drafts'
      }
    },

    components: {
      LookCard
    },

    mixins: [hasBackgroundMixin],

    data() {
      return {
        looks: []
      }
    },

    metaInfo() {
      return {
        title: this.$t('userLooks.pageTitle')
      }
    },

    created() {
      this.loadLooks()

      bus.$on('loadLooksRequested', this.loadLooks)
    },

    methods: {
      loadLooks() {
        let params = {
          sorting: 'time_created' + '{desc}'
        }

        if (this.$currentUser.isAgent) {
          params['client_id'] = 'null'
        }

        switch (this.type) {
          case 'published': {
            params.author_id = 'me'
            params.is_public = true
            break
          }

          case 'drafts': {
            params.author_id = 'me'
            params.is_draft = true
            break
          }

          case 'agent': {
            params['author_id.exclude'] = 'me'
            break
          }
        }

        lookService.get('me', params).then((data) => {
          this.looks = data.items.map((look) => {
            return Object.assign({}, look, { style: this.getStyle(look) })
          })
        })
      },

      getStyle(look) {
        return {
          background: look.previewUrl ? `url(${look.previewUrl}) center center / contain no-repeat` : 'none',
          'background-color': '#fff'
        }
      }
    },

    watch: {
      type() {
        this.loadLooks()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .user-looks {
    padding: 40px;

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .look {
      margin: 10px;
    }
  }
</style>
